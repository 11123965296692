:root {
    --item-width: 10rem;
  }
  

.login-form-container {
    
    min-height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.form-title{
    margin-bottom:1rem;
    color:#3333c4;
    font-size: 1.5rem;
    font-weight: bolder;
}


.login-form {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fdfdfd;

        border: 0.12rem solid #3333c4;
        box-shadow: 4px 2px 2px rgb(117, 117, 117);

        margin-top: 6rem;
        border-radius: 1.5rem;
        /* height: 20rem; */
        width: 35rem;

        padding-block: 2rem;
}


.ant-input-suffix{
    position: absolute;
    left: calc(var(--item-width) + 6.5rem);
    top: 0.1rem
}

.ant-form-item{
    height: 2rem;
    font-size: 1rem;
    margin-block: 1.2rem;
    position: relative
}

.ant-input{
    font-size: 0.7rem;
    height:1.5rem;
    width:var(--item-width);
    border-radius: 0.5rem;
    border-width: 0.1rem;
}

.ant-form-item-explain{
    width: 20rem;
    font-size: 0.8rem;  
    color: red
}
.ant-form-item-has-error{
    /* margin-inline-end: 0; */
    /* width: 10rem; */
}
.ant-input-affix-wrapper{
    /* position: relative; */
    width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ant-checkbox-wrapper{
    margin: 0.5rem;
}

.login-form-button{
    height: 2.5rem;
    min-width: 6rem;
    margin: 0.5rem;
    font-size: 1rem;
    font-weight: bolder;
    border-radius: 0.5rem;
    border-width: 0.1rem;
    /* background-color: #3333c4; */
    border-color: #3333c4;
    color: #3333c4;
}

.login-form-button:hover{
    cursor: pointer;
    background-color: #3333c4;
    color: white;
}

.site-form-item-icon{
    margin-inline-end: 1rem;
}

.response{
    font-size: 1rem;
    margin: 1rem;
    /* max-width: 35rem; */
}

.response.fail{
    font-size: 1rem;
    margin: 1rem;
    color: red;
    /* max-width: 35rem; */
}


.ant-space{
    height: 2rem;
    margin-block-end: 1rem;
    margin-inline-end: 1rem;
    width: 12rem;
    align-items: center;
}

.ant-space-item{
    width: 12rem;
}

.ant-space-item .ant-form-item{
    height: 2rem;
    /* margin-block-end: 1rem; */
}


.login-form-float {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-grid{
    display: grid;
    grid-template-columns: 13rem 13rem;
    max-height: 15rem ;
    overflow-y: scroll;
    margin: 0.5rem;
    padding: 1rem;
}
