.Modal {
    height: 100%;
    width: 100%;
    top:0;
    left:0; 
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 50rem;
    min-width: 60rem;

    max-width: 90rem;

    opacity: 1;

    word-wrap:break-word;
}


.Modal.hide{
    display: none;

}

.Modal-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.Modal-notice {
    z-index: 2;

    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.911);

    border-style: solid;
    border-radius: 1rem;
    border: 0.12rem solid #3333c4;
    box-shadow: 4px 2px 2px rgb(117, 117, 117);
    
    width: 40%;
    min-width:35rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding: 2rem;
}

.Modal-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 1rem;
}

.Modal-table{
    width: 100%;
    border-collapse: collapse;
    border-style: solid;
    margin-block: 1rem;

    margin: 1rem

}


.Modal-table tr{
    border-style: solid;
    width: 100%;
}

.Modal-table th{
    border-style: solid;
}


.Modal-button {
    cursor: pointer;
    border:solid;
    border-radius:0.5rem;
    padding:0.3rem;
    padding-inline:0.7rem;
    text-decoration: none;
    min-width: 4.5rem;
}

.Modal-button:hover{
    background-color: #3333c4;
    color: white;  
}

.Modal ul {
    text-align: left;
}