
  
.card {
color: black;
border-top: 10px solid;
background-color: #d8e5ffb0;
border-radius: 1rem;
box-shadow:         
0px 11px 8px -10px #CCC,
0px -11px 8px -10px #CCC; 

margin: 0.5rem;
margin-inline: 4rem;
max-width: 22rem;
min-width: 20rem;
min-height: 25rem;
padding: 1rem 0;
position: relative;
width: 100%;
text-align: center;

}

.card:hover{
    box-shadow: 0px 0px 6px 6px #7a91ed;
}

.card-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
}
.card-description p{
    margin:0;
    height:1rem
}
.card-description h2{
    margin:1rem;
    height:1rem
}

.card-billing{
    background-color: rgb(255, 255, 255);
    padding: 0.5rem   

}
.price {
font-size: 2rem;
font-weight: 700;
}
.card-features{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:1rem;
    font-weight: bold;
}
.card-features ul{
    margin: 0;
    margin-block: 0;
    width: 90%;
    padding: 0;
    text-align: left;
}

.card-features li{
    display: flex;
    justify-content: space-between;

}

.card-action{
    /* position: absolute; */
    bottom: 0.5rem;

}

.card-action{
    height: 2.5rem;
    width: 7rem;
    margin: 0.5rem;
    font-size: 1rem;
    font-weight: bolder;
    border-radius: 0.5rem;
    border-width: 0.1rem;
    border-color: black;
    color: black;
    background-color: none;
}

.card-action:hover{
    cursor: pointer;
    background-color: #3333c4;
    color: white;
}
