:root {
    --blue: #3333c4;
    --font-color:white;

  }

.Menu{
    position: relative;
    max-width: 2rem;
    margin-inline: 0.8rem;
   
}

.Menu:hover{
    color: auto;
}

.Menu.active{
    transform: rotate(90deg);
    transition-duration: 0.5s;
}

.Menu.inactive{
    transform: rotate(0deg);
    transition-duration:  0.5s;
}

.Menu-nav{
    transition-duration: 0.5s;
    
    cursor: auto;
    color: var(--font-color);
    background-color: var(--blue);
    position:absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0.5rem;
    border-radius: 0.5rem;

    width: 10rem;
    top: 3.5rem;
    right:-0.5rem;
}

.Menu-nav.active{
    opacity: 1;
}

.Menu-nav.inactive{
    opacity: 0;
    pointer-events:none;
}


.Menu-nav-item{

    width: 100%;
    min-height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-style: solid;
    border-color: var(--blue);

    border-radius: 0.5rem;
    text-decoration: none;
    color:var(--font-color);
    font-size: 0.8rem;
    margin-block: 0.2rem;
}

.Menu-nav-item:hover{
    cursor: pointer;
    border-color: white;  
    border-style: solid;
}