:root {
  --background-color:  #fff8db;
}

.App {
  font-family: serif;
  background-color:  var(--background-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 50rem; */
  min-width: 60rem;
  max-width: 90rem;
  height: 100%;
  /* min-height: 100vh; */
  /* background-image: url('img/1.png'); */
}

.App-logo {
  height: 5rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-weight: bolder;  
  background-color: var(--background-color);
  min-height: 6rem;
  min-width: 55rem;
  /* width: 65rem; */
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 2.5rem;
  color: #3333c4;


}

.App-title{
  display:flex;
  flex-direction: row;
  align-items: center;
}

.App-link {
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-inline: 0.5rem;

  min-height: 2.5rem;
  min-width: 15rem;
  text-decoration: none;

  border-right: 0.2rem solid;

  color: #3333c4;
}

.App-link-suffix{
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-size: 0.8rem;
  min-width: 10rem;
  text-align: left;
}

.App-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  min-width: 55rem;
  min-height: 40rem;
  width: 100%;

  background-color:  var(--background-color);
}

.App-footer{
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 40rem;
  
  min-height: 8rem;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



