.Home {

    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-width: 55rem;
    min-height: 20rem;

}
