.Payment-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fdfdfd;

    border: 0.12rem solid #3333c4;
    box-shadow: 4px 2px 2px rgb(117, 117, 117);

    margin-top: 6rem;
    border-radius: 1.5rem;
    height: 20rem;
    width: 35rem;
}

.payment-title {
    margin: 1rem;
    color:#3333c4;
    font-size: 1.5rem;
    font-weight: bolder;
    display: flex;
    align-items: center;
}

.payment-details{
    margin: 1rem;
    color:black;
    font-size: 1rem;
    font-weight: bolder;
}