:root{
    --font-color: #3333c4;
}


.TopNav {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    height: 100%;
    font-size: 1.2rem;

    min-width: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: bolder;

}

.Right-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-height: 3rem;
    min-width: 28rem;
}

.Nav-item{
    border-radius:0.5rem;
    padding:0.2rem;
    padding-inline:0.5rem;
    margin: 0.2rem
    
    
}

.Nav-item:hover{
    border-radius:0.5rem;
    background-color: #3333c4;
    color: white;
}

.Sign-up {
    border:solid;
    border-radius:0.5rem;
    padding:0.3rem;
    padding-inline:0.7rem;
    text-decoration: none;
    min-width: 4.5rem;
}


.Sign-up:hover {
    background-color: #3333c4;
    color: white;   
}

.Nav-dropdown{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    /* padding-right: 1rem; */
}

.Nav-item:link { text-decoration: none; }
.Nav-item:visited { text-decoration: none;}
.Nav-item:hover { text-decoration: none; cursor: pointer; color:'white'}
.Nav-item:active { text-decoration: none; }