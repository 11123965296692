.features {
    background-color: #d8e5ffb0;

    color: rgb(51, 51, 51);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: space-between;
    width: 90%;
    min-width: 55rem;
    /* min-height: 20rem; */

    border-radius: 1rem;
    
    box-shadow:         
    0px 11px 8px -10px #CCC,
    0px -11px 8px -10px #CCC; 
}



.features-title {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;
    font-weight: bold;
    height: 4rem;

}

.features-desc{
    font-family: "FuturaStd-Book", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1rem;
    min-height: 6rem;
    width: 60%;
}

.features-cardview {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 85%;
    min-width: 45rem;
    margin: 1rem
}


.card-row{
    display: flex;
    flex-direction: row;
    width: 95%;
    min-width: 45rem;
    justify-content: space-evenly;
}

