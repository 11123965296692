.FAQContainer{
    width: 70%;
    text-align: left;  
    text-justify: auto;
    margin: 3rem;

    min-width: 60rem;
    
}

.FAQTitle{
    color: #3333c4 ;
    font-size: 3rem;
    margin-block-end: 2rem;
    display: flex;
    align-items: center;
}

.FAQDetails{
    background-color: #d8e5ffb0;
    padding: 0.5rem;
    border-radius: 1rem;
}

.FAQContainer ul{
    padding-inline: 1.5rem;
}
.FAQContainer summary{
    cursor: pointer;
    color: #3333c4 ;
    font-size: 1.3rem;
    font-weight: bold;
    margin-block: 1rem;
}

.FAQContainer details{

    font-size: 1.2rem;
    padding-block: 0.2rem;
    color: rgb(35, 35, 35)
}

.FAQContainer table {
    border-collapse: collapse;
    border-style: solid;
    width: 100%;
    margin-block: 1rem;
}

.FAQContainer th {
    font-weight: lighter;
    text-align: center;
    border-style: solid;
}

.table-title th{
    background-color: lightgray;
    font-weight: bold;
}