.Pricing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin-block: 2rem; */
    min-height: 35rem;
    width: 100%;
    background-color: rgb(236, 236, 236, 0.);
/* 
    box-shadow:         
    inset 0px 11px 8px -10px #CCC,
    inset 0px -11px 8px -10px #CCC;  */
}
