.intro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 55rem;
    min-height:18rem;
    width: 85%;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
}


.slogan {
    color:rgb(42, 42, 42);
    background-color: rgba(116, 116, 116, 0.1);
    color:#3333c4;
    
    width: 33%;
    min-width: 20rem;
    min-height: 10rem;
    /* border-radius: 2rem; */

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;
}
.sliderimg{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin:1rem;
    font-size: 2rem;
    width: 100%;
    height: 10rem;
    object-fit: cover;
    background-size: 100% auto;
    background-color: rgba(116, 116, 116, 0.1);
    min-width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;

  }

 .alice-carousel__wrapper{
    border-bottom-right-radius: 5rem
 }

 .get-started{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
    height: 3rem;
    min-width: 15rem;;
    width: 15%;
    border-radius: 1rem;
    border-style: solid;
    text-decoration: none;
 }

 
 .get-started:hover{
    cursor: pointer;
    background-color: #3333c4;
    color: white;
 }