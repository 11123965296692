

.card-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    
    width: 18rem;
    height: 7rem;

    margin: 1rem;
}

.icon-container {
    display:flex;
    justify-content: center;
    align-items: center;

    height: 5rem;
    width: 20%
}

.content-container {
    width: 80%;
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.title-container{
    height: 2rem;
    font-size: 1rem;
    font-weight: bold;
    min-width: 15rem;
    margin-bottom: 0.5rem;
}

.body-container{
    height: 6.5rem;
    max-width: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}